import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  wrapper: {
    margin: '10px 0'
  },
  statusLabel: {
    marginRight: '10px', // Space between the label and the slider
    fontSize: '16px'
  },
  statusText: {
    minWidth: 60,
    fontSize: '16px',
    marginLeft: '10px',
    textAlign: 'left'
  },
  switch: {
    position: 'relative',
    display: 'inline-block',
    width: '52px',
    height: '32px'
  },
  slider: {
    position: 'absolute',
    cursor: 'pointer',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: '#358F6E', // Default background color for the slider
    transition: '.4s',
    borderRadius: '34px'
  },
  sliderHandle: {
    position: 'absolute',
    height: '24px',
    width: '24px',
    left: '4px', // Default position for "disabled"
    bottom: '4px',
    backgroundColor: 'white',
    borderRadius: '50%',
    transition: '.4s'
  }
});
