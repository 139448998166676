import { AssetsContext } from '@/GlobalProvider/GlobalProvider';
import { inIframe } from '@/utils';
import * as React from 'react';
import { useContext } from 'react';
import { createUseStyles } from 'react-jss';
import { Menu } from '../Menu';

const useStyles = createUseStyles({
  logo: {
    position: 'absolute',
    top: 10,
    right: 0,
    width: 300,
    height: 70,
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain'
    },
    '@media (max-width: 576px)': {
      width: 250
    }
  }
});

export const Header = () => {
  const assets = useContext(AssetsContext);
  const styles = useStyles();
  const isInIframe = inIframe();

  if (isInIframe) return null;

  const { imageUrl, asset_list } = assets || {};
  return (
    <div>
      <Menu />
      {imageUrl && asset_list?.company_logo_image && (
        <div className={styles.logo}>
          <img src={`${imageUrl}/${asset_list?.company_logo_image}`} />
        </div>
      )}
    </div>
  );
};
