import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  iframeWrapper: {
    textAlign: 'center',
    '& h2': {
      marginBottom: '15px'
    }
  },
  iframe: {
    width: '960px',
    height: '80vh',
    borderRadius: '20px',
    border: 'none',
    '@media (max-width: 1024px)': {
      width: '85vw'
    },
    '@media (max-width: 767px)': {
      maxHeight: '500px'
    }
  }
});
