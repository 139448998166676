import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useStyles } from './styles'; // Adjust the import path as needed
import { AlertContext, AppContext } from '@/GlobalProvider/GlobalProvider';
import { useNavigate } from 'react-router-dom';
import { ContentBlock, Header, Loader, Menu } from '@/components';
import { getAttendants } from '@/api';
import { GoPlus } from 'react-icons/go';
import { Tabs } from '@/components/Tabs';

export type Attendant = {
  entity: string;
  enabled: boolean;
  identity: string;
  role: string;
  employeeId: string;
  name: string;
  email: string;
};

type AttendantsState = {
  active: Attendant[];
  inactive: Attendant[];
};

enum TabLabels {
  Active = 'Active',
  Inactive = 'Inactive'
}

export const AttendantManagement: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useContext(AppContext);
  const { setAlert } = React.useContext(AlertContext);

  const [attendants, setAttendants] = useState<AttendantsState>({
    active: [],
    inactive: []
  });
  const [activeTab, setActiveTab] = useState<string>(TabLabels.Active);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchAttendants = async () => {
    setLoading(true);
    try {
      const attendants = await getAttendants(id);
      // loop over list and separate active and inactive attendants
      const active = attendants.filter((attendant) => attendant.enabled);
      const inactive = attendants.filter((attendant) => !attendant.enabled);

      // set the state
      setAttendants({ active, inactive });
    } catch (error) {
      setAlert({
        variation: 'error',
        text: <span>Unable to get store attendants: Please try again</span>
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchAttendants();
    })();
  }, []);

  const handleCircleClick = (attendant: Attendant) => {
    navigate(`/edit-attendant/${attendant.employeeId}`, {
      state: { attendant }
    });
  };

  const handleCreateClick = () => {
    navigate(`/create-attendant`);
  };

  return (
    <ContentBlock hasBackButton loading={loading}>
      <div className={classes.tabTitle}>Attendants</div>
      <Tabs
        labels={[TabLabels.Active, TabLabels.Inactive]}
        value={activeTab}
        onChange={setActiveTab}
      />
      <div className={classes.circleContainer}>
        {activeTab === TabLabels.Active ? (
          <>
            <div>
              <div
                className={classes.newAttendantButton}
                onClick={() => {
                  handleCreateClick();
                }}
              >
                <GoPlus className={classes.plusIcon} />
              </div>
              <div className={classes.personName}>+ Add New</div>
            </div>
            {attendants.active.map((person) => (
              <div key={person.name} onClick={() => handleCircleClick(person)}>
                <div className={classes.personCircle}>
                  <div className={classes.innerCircle}>
                    {GetInitials(person.name)}
                  </div>
                </div>
                <div className={classes.personName}>
                  {SetInitialToUpperCase(person.name)}
                </div>
              </div>
            ))}
          </>
        ) : (
          <>
            {attendants.inactive.map((person) => (
              <div key={person.name} onClick={() => handleCircleClick(person)}>
                <div className={classes.personCircle}>
                  <div className={classes.innerCircle}>
                    {GetInitials(person.name)}
                  </div>
                </div>
                <div className={classes.personName}>{person.name}</div>
              </div>
            ))}
          </>
        )}
      </div>
    </ContentBlock>
  );
};

// Helper function to extract initials from the name
export function GetInitials(name: string): string {
  // Convert innitials to uppercase
  return name
    .split(' ')
    .map((n) => n[0])
    .join('')
    .toUpperCase();
}

export function SetInitialToUpperCase(name: string): string {
  return name
    .split(' ')
    .map((n) => n[0].toUpperCase() + n.slice(1))
    .join(' ');
}
