import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles((theme) => ({
  customer: {
    minWidth: 470,
    '@media (max-width: 991px)': {
      minWidth: 'min-content'
    }
  },
  customerHeader: {
    marginBottom: 30,
    '@media (max-width: 576px)': {
      marginBottom: 15
    }
  },
  previousScan: {
    marginBottom: 20
  },
  qrCodeBlockMobile: {
    marginBottom: 70,
    display: 'none',
    '& canvas': {
      width: '100%!important',
      height: '100% !important',
      maxWidth: '400px',
      maxHeight: '400px'
    },
    '@media (max-width: 768px)': {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 50
    }
  },
  backButton: {
    width: '100%',
    maxWidth: 320,
    '@media (max-width: 768px)': {
      maxWidth: '100%',
      textAlign: 'center'
    }
  },
  br: {
    display: 'none',
    '@media (max-width: 768px)': {
      display: 'block'
    }
  },
  content: {
    overflow: 'hidden'
  },
  title: {
    marginTop: 20,
    marginBottom: 30,
    textTransform: 'uppercase',
    fontWeight: 700,
    '@media (max-width: 576px)': {
      marginTop: 0,
      marginBottom: 15
    }
  },
  subtitle: {
    paddingBottom: 10
  },
  personalInfo: {
    paddingBottom: 20,
    '@media (max-width: 576px)': {
      paddingBottom: 0
    }
  },
  actionBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
    flexWrap: 'wrap',
    maxWidth: '470px',
    '@media (max-width: 576px)': {
      marginBottom: 0
    }
  },
  button: {
    maxWidth: 245,
    width: 'max-content',
    marginBottom: 15,
    '@media (max-width: 991px)': {
      maxWidth: '50%',
      width: 'auto',
      flex: 'auto'
    }
  },
  desktopText: {
    '@media (max-width: 576px)': {
      display: 'none'
    }
  },
  mobileText: {
    display: 'none',
    '@media (max-width: 576px)': {
      display: 'inline-block'
    }
  },
  scanBlockTitle: {
    marginBottom: 20
  },
  scan: {
    borderRadius: 15,
    border: `1px solid rgba(255, 255, 255, 0.20)`,
    padding: '10px 10px',
    '@media (max-width: 768px)': {
      marginBottom: 20
    },
    '@media (max-width: 576px)': {
      padding: '0 10px'
    }
  },
  scanDetails: {
    padding: '5px 0'
  },
  continueShoppingButton: {
    textAlign: 'center',
    width: '100%',
    maxWidth: 340,
    '& > button': {
      width: '100%'
    },
    '@media (max-width: 768px)': {
      maxWidth: '100%',
      textAlign: 'center'
    }
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 30,
    alignItems: 'flex-end',
    '& button': {
      maxWidth: 200
    },
    '@media (max-width: 768px)': {
      flexDirection: 'column-reverse',
      gap: '20px',
      padding: '0 15px'
    }
  },
  refresh: {
    maxWidth: 200,
    marginBottom: 10,
    '@media (max-width: 768px)': {
      maxWidth: '100%'
    }
  },
  confirmationMessage: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 20,
    border: '2px solid #000'
  },
  confirmationButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 20
  },
  confirmationButton: {
    maxWidth: 200,
    marginBottom: 10,
    '@media (max-width: 768px)': {
      maxWidth: '100%'
    }
  },
  confirmDeposit: {
    paddingLeft: '4px',
    '@media (max-width: 768px)': {
      marginBottom: '20px'
    }
  },
  confirmDepositLabel: {
    paddingLeft: '4px',
    color: `${theme.colors.white}`,
    '& a': {
      color: `${theme.colors.white}`
    }
  }
}));
