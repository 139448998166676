import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './styles';
import { inIframe } from '@/utils';
import { Flex } from '@aws-amplify/ui-react';
import { useState } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { Header, Loader, Typography } from '@/components';

interface ContentBlockProps {
  children: React.ReactNode;
  hasBackButton?: boolean;
  backHandler?: () => void;
  additionalInfo?: React.ReactNode;
  hideAdditionalInfo?: (v: null) => void;
  showAdditionalInfo?: boolean;
  additionalInfoTitle?: string | null;
  loading?: boolean;
  largeSize?: boolean;
  additionalInfoLargeSize?: boolean;
}

export const ContentBlock: React.FC<ContentBlockProps> = ({
  children,
  hasBackButton,
  backHandler,
  additionalInfo,
  showAdditionalInfo,
  hideAdditionalInfo,
  additionalInfoTitle,
  loading,
  largeSize,
  additionalInfoLargeSize
}) => {
  const navigate = useNavigate();

  const isInIframe = inIframe();
  const styles = useStyles({ isInIframe });

  const onBackButtonPress = backHandler || (() => navigate('/'));

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header />
          <Flex justifyContent={'center'} className={styles.wrapper}>
            <div
              className={`${styles.contentBlock} ${largeSize && styles.large}`}
            >
              {hasBackButton && (
                <div className={styles.backButton} onClick={onBackButtonPress}>
                  <span />
                </div>
              )}
              <div className={styles.content}>{children}</div>
            </div>
            <div
              className={`${styles.additionalInfo} ${
                showAdditionalInfo && styles.show
              } ${additionalInfoLargeSize && styles.showFullScreen}`}
            >
              <Flex
                className={styles.hideButton}
                alignItems={'center'}
                onClick={() => hideAdditionalInfo(null)}
              >
                <IoIosArrowBack />
                <Typography component={'p'}>
                  {additionalInfoTitle && additionalInfoTitle}
                </Typography>
              </Flex>
              {showAdditionalInfo && additionalInfo}
            </div>
          </Flex>
        </>
      )}
    </>
  );
};
