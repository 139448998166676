import * as React from 'react';
import { forwardRef } from 'react';
import { TextField, TextFieldProps } from '@aws-amplify/ui-react';

export const TextInput = forwardRef<
  HTMLInputElement,
  TextFieldProps & React.InputHTMLAttributes<HTMLInputElement>
>(({ ...props }, ref) => {
  return (
    <TextField
      ref={ref}
      borderRadius={10}
      autoComplete="off"
      direction="column"
      hasError={false}
      inputMode="text"
      label="Last name"
      labelHidden={false}
      color={'#fff'}
      type="text"
      wrap="nowrap"
      {...props}
      className={`custom-input ${props.className}`}
    />
  );
});
