import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  qrCodeButtonWrapper: {
    cursor: 'pointer',
    '& svg': {
      width: 30,
      height: 30
    }
  }
});
