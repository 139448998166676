import { useStyles } from './styles';
import { Flex } from '@aws-amplify/ui-react';
import { Typography } from '@/components';
import { Link } from 'react-router-dom';
import * as React from 'react';
import * as QRCode from 'qrcode.react';

const checkHAWGValid = (currentCustomer) => {
  const { height, dateOfBirth, weight, gender } = currentCustomer || {};

  return !!height && !!dateOfBirth && !!weight && !!gender;
};

export const CustomerQRCode = ({ currentCustomer }) => {
  const styles = useStyles();
  const isHAWGValid = checkHAWGValid(currentCustomer);

  if (isHAWGValid) {
    return (
      <Flex
        alignItems={'center'}
        justifyContent={'center'}
        direction={'column'}
        className={styles.leftBlock}
      >
        <div className={styles.qrCodeBlock}>
          <QRCode id="qrCode" value={window.btoa(currentCustomer.id)} />
        </div>
        <Typography component={'p'}>Scan the QR code</Typography>
      </Flex>
    );
  }

  return (
    <Flex
      alignItems={'center'}
      justifyContent={'center'}
      direction={'column'}
      className={styles.leftBlock}
    >
      <Typography component="h3" className={styles.alertMessage}>
        This account was migrated.
        <br /> Please{' '}
        <Link
          className={styles.alertMessageLink}
          to={`/createCustomer/${currentCustomer.id}`}
        >
          Update Profile
        </Link>{' '}
        before measuring.
      </Typography>
    </Flex>
  );
};
