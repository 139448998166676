import * as React from 'react';
import { useStyles } from './styles';

enum ButtonState {
  Active = 'active',
  Inactive = 'inactive'
}

interface TabsProps {
  labels: Array<string>;
  value: string;
  onChange: (value: string) => void;
}

export const Tabs: React.FC<TabsProps> = ({ labels, value, onChange }) => {
  const styles = useStyles();
  return (
    <div className={styles.tabButtons}>
      {labels.map((item) => (
        <button
          key={item}
          className={`${styles.tabButton} ${
            value === item ? ButtonState.Active : ButtonState.Inactive
          }`}
          onClick={() => onChange(item)}
        >
          {item}
        </button>
      ))}
    </div>
  );
};
