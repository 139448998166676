import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  wrapper: {
    marginTop: 32
  },
  tableWrapper: {
    overflowY: 'auto',
    overflowX: 'visible',
    width: 390,
    maxHeight: 420,
    marginTop: 35,
    position: 'relative',
    '@media (max-width: 991px)': {
      width: '100%'
    }
  },
  tableWithPreview: {
    marginTop: 0,
    '& table': {
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0
    }
  },
  image: {
    maxWidth: 390,
    maxHeight: 390,
    width: '100%',
    borderBottomRightRadius: 16,
    borderBottomLeftRadius: 16,
    overflow: 'hidden',
    background: '#fff',
    border: '20px solid #000',
    position: 'relative',
    '& img': {
      width: '100%',
      height: '100%',
      mixBlendMode: 'difference',
      marginBottom: '-5px'
    },
    '@media (max-width: 576px)': {
      maxWidth: 'initial',
      maxHeight: 'initial'
    }
  },
  loader: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: '5',
    backgroundColor: 'rgba(0,0,0, 0.5)'
  }
});
