import { ContentBlock, Typography, Header } from '@/components';
import { AppContext } from '@/GlobalProvider/GlobalProvider';
import * as React from 'react';
import { useContext, FC, useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { Auth } from 'aws-amplify';
import * as classNames from 'classnames';
import { useIframeMessageHandler } from '@/utils';

export const useStyles = createUseStyles({
  header: {
    textAlign: 'center',
    marginBottom: 40,
    '@media (max-width: 576px)': {
      marginBottom: 30
    }
  },
  title: {
    marginBottom: 15
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 25
  },
  itemValue: {
    fontWeight: 400
  }
});

export const About: FC = () => {
  const { id } = useContext(AppContext);
  const styles = useStyles();

  const [email, setEmail] = useState(null);

  const getEmail = React.useCallback(async () => {
    const user = await Auth.currentUserInfo();
    setEmail(user.attributes.email);
  }, []);

  useIframeMessageHandler();

  useEffect(() => {
    getEmail();
  }, []);

  return (
    <ContentBlock hasBackButton>
      <div className={styles.header}>
        <Typography className={styles.title} component="h2">
          About
        </Typography>
        <Typography component="h6">Information about your session</Typography>
      </div>
      <div className={styles.content}>
        <div>
          <Typography component="h4">
            email: <span className={styles.itemValue}>{email}</span>
          </Typography>
          <Typography component="h4">
            app-id: <span className={styles.itemValue}>{id}</span>
          </Typography>
          <Typography component="h4">
            webpage version:{' '}
            <span className={classNames(styles.itemValue, 'webpage-version')} />
          </Typography>
        </div>
      </div>
    </ContentBlock>
  );
};
