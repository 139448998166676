import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { useContext } from 'react';
import { AssetsContext } from '@/GlobalProvider/GlobalProvider';

function useAuthentication() {
  const { behavior_list } = useContext(AssetsContext);
  const attendantToken = window.localStorage.getItem('attendantToken');
  const attendant_flow = behavior_list.attendant_flow === 'true';

  return attendant_flow && !attendantToken;
}

export const ProtectedRoute = ({ children }: any) => {
  const isNoAuthentication = useAuthentication();

  if (isNoAuthentication) {
    return <Navigate to="/enter-attendant-id" />;
  }
  return children;
};
