import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  tabButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px 0 0'
  },
  tabButton: {
    flex: '1',
    cursor: 'pointer',
    padding: '10px',
    background: 'transparent',
    textAlign: 'center',
    borderTop: 'transparent',
    borderLeft: 'transparent',
    borderRight: 'transparent',
    borderBottom: '2px solid transparent',
    '&.active': {
      background: 'transparent',
      color: '#fff',
      borderBottom: '2px solid #fff'
    },
    '&.inactive': {
      background: 'transparent',
      color: 'rgba(255, 255, 255, 0.50)',
      borderBottom: '1px solid rgba(255, 255, 255, 0.50)'
    }
  }
});
