import { jwtDecode } from 'jwt-decode';

export type AttendantUserType = {
  attendant_name: string;
  employee_id: string;
  email: string;
  entity: string;
  exp: number;
  iat: number;
  role: string;
};

export const getAttendantUser = (jwt: string): AttendantUserType => {
  const data = jwtDecode<AttendantUserType>(jwt);
  return data;
};
