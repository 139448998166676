export const addAttendantAuthorizationHeader = (init) => {
  const token = window.localStorage.getItem('attendantToken');
  if (token) {
    const bearerToken = `Bearer ${token}`;
    init.headers = {
      ...init.headers,
      'attendant-authorization': bearerToken
    };
  }
  return init;
};
