import * as React from 'react';
import { useStyles } from './styles';
import { Flex } from '@aws-amplify/ui-react';
import { FC } from 'react';

interface SwitchProps {
  checked: boolean;
  activeText: string;
  inactiveText: string;
  title: string;
  onChange: (v: boolean) => void;
}
export const Switch: FC<SwitchProps> = ({
  checked,
  activeText,
  inactiveText,
  title,
  onChange
}) => {
  const styles = useStyles();
  return (
    <Flex
      className={styles.wrapper}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <span className={styles.statusLabel}>{title}:</span>
      <label className={styles.switch}>
        <input
          type="checkbox"
          checked={checked}
          onChange={(e) => onChange(!checked)}
        />
        <span
          className={styles.slider}
          style={{
            backgroundColor: checked ? '#358F6E' : 'red'
          }}
        >
          <div
            className={styles.sliderHandle}
            style={{ left: checked ? '24px' : '4px' }} // Adjust these values based on the size of your slider
          ></div>
        </span>
      </label>
      <span className={styles.statusText}>
        {checked ? activeText : inactiveText}
      </span>
    </Flex>
  );
};
