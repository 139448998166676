import * as React from 'react';
import { useStyles } from '../styles';
import { Button } from '@/components';
import { inIframe } from '@/utils';
import { useNavigate } from 'react-router-dom';

export const HomeNavButtons = () => {
  const isInIframe = inIframe();
  const styles = useStyles({ isInIframe });
  const navigate = useNavigate();
  return (
    <div className={styles.buttons}>
      <Button
        onClick={() => navigate('/createCustomer')}
        className={styles.button}
        text="Sign up"
        size="large"
      />
      <Button
        onClick={() => navigate('/search')}
        className={styles.button}
        text="Search customer"
        size="large"
      />
    </div>
  );
};
