import { Auth } from 'aws-amplify';

export const signOut = async () => {
  window.localStorage.removeItem('attendantToken');
  try {
    await Auth.signOut();
  } catch (error) {
    console.log('error signing out: ', error);
  }
};
