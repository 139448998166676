import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  formInputs: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    '@media (max-width: 576px)': {
      flexDirection: 'column'
    }
  },
  input: {
    padding: '0 10px',
    width: 370,
    '@media (max-width: 576px)': {
      padding: '0',
      width: '100%',
      marginBottom: 10
    },
    '& label': {
      color: 'rgba(255, 255, 255, 0.8)'
    },
    '& input': {
      color: '#fff',
      '&:focus': {
        borderColor: '#CACACA'
      }
    }
  },
  buttonBlock: {
    paddingTop: 40,
    maxWidth: 210,
    margin: '0 auto',
    '@media (max-width: 576px)': {
      paddingTop: 10
    }
  }
});
