import * as React from 'react';
import { useStyles } from './styles';
import { Flex, Radio, RadioGroupField } from '@aws-amplify/ui-react';
import { FC } from 'react';

interface RadioSwitchGroupProps {
  handleChange: (e: React.ChangeEvent<any>) => void;
  values: string[];
  value: string;
  label: string;
  subLabels: string[];
  name?: string;
}

export const RadioSwitchGroup: FC<RadioSwitchGroupProps> = ({
  handleChange,
  values,
  value,
  label,
  subLabels,
  name
}) => {
  const styles = useStyles();

  return (
    <RadioGroupField
      className={styles.radioGroupField}
      label={label}
      name={name}
    >
      <Flex className={styles.radioGroupWrapper}>
        <Radio
          className={`${styles.radioButton} ${
            value === values[0] ? 'checked' : ''
          }`}
          value={values[0]}
          checked={value === values[0]}
          onChange={handleChange}
        >
          {subLabels[0].replace(/&/g, ' & ')}
        </Radio>
        <Radio
          className={`${styles.radioButton} ${
            value === values[1] ? 'checked' : ''
          }`}
          value={values[1]}
          checked={value === values[1]}
          onChange={handleChange}
        >
          {subLabels[1].replace(/&/g, ' & ')}
        </Radio>
      </Flex>
    </RadioGroupField>
  );
};
