const keysSets = {
  default: {
    Neck: 'Neck',
    Overarm: 'Overarm',
    WristRight: 'Right Wrist',
    WristLeft: 'Left Wrist',
    Stomach: 'Abdomen',
    Waist: 'Body Waist',
    SleeveRight: 'Right Arm Length',
    SleeveLeft: 'Left Arm Length',
    ShoulderWidth: 'Shoulder',
    ChestCB: 'Chest',
    HipsCB: 'Hip',
    OutseamRight: 'Right Outside Leg',
    OutseamLeft: 'Left Outside Leg',
    BicepRight: 'Right Bicep',
    BicepLeft: 'Left Bicep',
    ThighRight: 'Right Thigh',
    ThighLeft: 'Left Thigh',
    CalfRight: 'Right Calf',
    CalfLeft: 'Left Calf'
  }
};

type ScanMeasurements = Record<string, number>;

export const getScanMeasurements = (
  appId: string,
  scanMeasurements: ScanMeasurements
) => {
  const keys = keysSets[appId] || keysSets.default;

  return Object.entries(keys).map(([key, label]) => {
    const value = Math.round(scanMeasurements[key] * 10) / 10;
    return [label, value];
  });
};
