import * as React from 'react';

import { useStyles } from './styles';
import { useContext } from 'react';
import { AssetsContext } from '@/GlobalProvider/GlobalProvider';

export const QuestionnaireIframe = ({ customer }: any) => {
  const styles = useStyles();

  const {
    behavior_list: { fit_preference_url }
  } = useContext(AssetsContext);

  const srcUrl = `${fit_preference_url}&firstName=${customer.firstName}&lastName=${customer.lastName}&email=${customer.email}&user_id=${customer.id}`;

  return (
    <div style={{ padding: '25px 0' }}>
      <iframe src={srcUrl} width="600" height="700"></iframe>
    </div>
  );
};
