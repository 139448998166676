import * as React from 'react';
import { useStyles } from '../styles';
import { AttendantUserInfo } from '@/components';
import { inIframe } from '@/utils';
import { Link } from 'react-router-dom';
import { AttendantContext } from '@/GlobalProvider/GlobalProvider';
import { HomeNavButtons } from '@/pages/Home/HomeNavButtons';

export const AttendantFlow = () => {
  const isInIframe = inIframe();
  const styles = useStyles({ isInIframe });
  const { user } = React.useContext(AttendantContext);
  const isAttendantAuthenticated =
    !!user && !!window.localStorage.getItem('attendantToken');

  return (
    <>
      {isAttendantAuthenticated ? (
        <>
          <AttendantUserInfo />
          <HomeNavButtons />
        </>
      ) : (
        <Link className={styles.link} to={'/enter-attendant-id'}>
          Tap to begin
        </Link>
      )}
    </>
  );
};
