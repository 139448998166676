import { useStyles } from './styles';
import { Typography } from '../Typography';
import * as React from 'react';
import { AttendantContext } from '@/GlobalProvider/GlobalProvider';
import { GoPlus } from 'react-icons/go';
import { HiOutlineSwitchHorizontal } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

export const AttendantUserInfo = ({ showAddAttendantButton = false }) => {
  const styles = useStyles();
  const { user, setAttendantData } = React.useContext(AttendantContext);
  const navigate = useNavigate();

  const handleSwitchAttendant = () => {
    window.localStorage.removeItem('attendantToken');
    setAttendantData(null);
    navigate('/enter-attendant-id');
  };

  if (showAddAttendantButton) {
    return (
      <div className={styles.newAttendant}>
        <Typography component="h3" className={styles.name}>
          New Attendant
        </Typography>
        <button className={styles.addButton}>
          <GoPlus className={styles.plusIcon} />
        </button>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.topBlock}>
        <div>
          <p className={styles.title}>Current Attendant</p>
          <Typography component="h3" className={styles.name}>
            {user?.attendant_name}
          </Typography>
        </div>
      </div>
      <button className={styles.switchButton} onClick={handleSwitchAttendant}>
        Switch Attendant
        <HiOutlineSwitchHorizontal className={styles.switchIcon} />
      </button>
    </div>
  );
};
