import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  editAttendantTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center',
    paddingBottom: '15px' // Space between text and underline
  },
  attendantId: {
    fontSize: '16px',
    fontWeight: 'normal',
    alignItems: 'center'
  },
  editAttendantBody: {
    width: '480px',
    display: 'flex',
    padding: '10px',
    flexDirection: 'column',
    alignItems: 'center',
    '@media (max-width: 576px)': {
      width: '100%'
    }
  },
  formField: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px', // This adds space between each form field
    alignItems: 'flex-start'
  },
  textInput: {
    borderRadius: '10px',
    border: '1px solid #000',
    padding: '4px 8px',
    marginTop: '0px',
    width: '320px',
    boxSizing: 'border-box'
  },
  editAttendantFooter: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px'
  },
  attendantsButton: {
    backgroundColor: 'black',
    color: '#fff',
    textAlign: 'center',
    padding: '8px, 24px, 8px, 24pxx',
    border: 'none',
    borderRadius: '20px',
    cursor: 'pointer',
    display: 'inline-block',
    justifyContent: 'center',
    marginBottom: '15px',
    width: '45%',
    gap: '10px',
    height: '36px',
    marginRight: '15px'
  },
  saveButton: {
    maxWidth: '230px'
  },
  filed: {
    width: '320px'
  }
});
