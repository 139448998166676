import * as React from 'react';
import { useContext } from 'react';
import { Header } from '@/components';
import { useStyles } from './styles';
import { AssetsContext } from '@/GlobalProvider/GlobalProvider';
import { inIframe, useIframeMessageHandler } from '@/utils';
import { HomeNavButtons } from '@/pages/Home/HomeNavButtons';
import { AttendantFlow } from '@/pages/Home/AttendantFlow';

export const Home = () => {
  const isInIframe = inIframe();
  const styles = useStyles({ isInIframe });
  const { behavior_list } = useContext(AssetsContext) || {};
  const {
    imageUrl,
    asset_list: { welcome_foreground_image }
  } = useContext(AssetsContext);

  useIframeMessageHandler();

  return (
    <div className={styles.home}>
      <Header />
      {!isInIframe && (
        <div className={styles.logoWrap}>
          <img
            className={styles.logo}
            src={`${imageUrl}/${welcome_foreground_image}`}
          />
        </div>
      )}
      {behavior_list?.attendant_flow === 'true' ? (
        <AttendantFlow />
      ) : (
        <HomeNavButtons />
      )}
    </div>
  );
};
