import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  refreshButtonWrapper: {
    width: 48,
    height: 48,
    borderRadius: '50%',
    background: '#2AB477',
    cursor: 'pointer',
    '& svg': {
      width: 30,
      height: 30
    }
  },
  loading: {
    cursor: 'default',
    opacity: 0.8
  }
});
