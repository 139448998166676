import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { createAttendant } from '@/api';
import {
  AppContext,
  AlertContext,
  AttendantContext
} from '@/GlobalProvider/GlobalProvider';
import { Attendant } from '@/pages';
import { useStyles } from './styles';
import { Button, ContentBlock, Header, Menu, TextInput } from '@/components';
import { SelectField } from '@aws-amplify/ui-react';
import { Formik, useFormik } from 'formik';
import { object as yup, string } from 'yup';
import { UserRoles } from '@/constants';

export const CreateAttendant: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = React.useContext(AppContext);
  const { setAlert } = React.useContext(AlertContext);
  const { user } = React.useContext(AttendantContext);

  const handleClose = () => {
    navigate('/attendantManagement');
  };

  const handleSave = async () => {
    try {
      const newAttendant: Attendant = {
        entity: '',
        identity: '',
        enabled: true,
        name: name,
        email: email,
        role: role,
        employeeId: employeeId
      };

      const attendantResponse = await createAttendant(id, newAttendant);

      if (attendantResponse.employeeId === newAttendant.employeeId) {
        setAlert({
          variation: 'success',
          text: React.createElement(
            'span',
            null,
            'Attendant created successfully'
          )
        });
        handleClose();
      } else {
        setAlert({
          variation: 'error',
          text: React.createElement(
            'span',
            null,
            'Unable to create attendant: Please try again'
          )
        });
      }
    } catch (error) {
      setAlert({
        variation: 'error',
        text: React.createElement(
          'span',
          null,
          `Unable to create attendant: ${error.message}`
        )
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      employeeId: '',
      email: user?.email,
      role: UserRoles.Attendant
    },
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: () => handleSave(),
    validationSchema: yup({
      name: string()
        .trim()
        .matches(/^\S+ \S+.*$/, 'Enter both first and last name')
        .required('Enter attendant name'),
      employeeId: string()
        .trim()
        .matches(/^[0-9]{5,6}$/, 'Employee ID must be 5 to 6 digits long.')
        .required('Enter attendant ID'),
      email: string()
        .trim()
        .required('Email address is required')
        .email('Wrong email format')
    })
  });

  const { name, employeeId, email, role } = formik.values || {};

  return (
    <ContentBlock
      hasBackButton
      backHandler={() => navigate(`/attendantManagement`)}
    >
      <div className={classes.createAttendantTitle}>Create Attendant</div>
      <Formik onSubmit={handleSave} {...formik}>
        <div className={classes.createAttendantBody}>
          <TextInput
            className={classes.filed}
            label="Attendant Name"
            type="text"
            name="name"
            placeholder="Enter attendant name"
            value={name}
            hasError={!!formik.errors.name}
            errorMessage={formik.errors.name}
            onChange={(e) => formik.setFieldValue('name', e.target.value)}
          />
          <TextInput
            className={classes.filed}
            label="Attendant ID"
            type="text"
            name="employeeId"
            placeholder="Enter attendant id"
            value={employeeId}
            hasError={!!formik.errors.employeeId}
            errorMessage={formik.errors.employeeId}
            onChange={(e) => formik.setFieldValue('employeeId', e.target.value)}
          />
          <TextInput
            className={classes.filed}
            label="Attendant Email"
            type="email"
            name="email"
            placeholder="Enter attendant email"
            value={email}
            hasError={!!formik.errors.email}
            errorMessage={formik.errors.email}
            onChange={(e) => formik.setFieldValue('email', e.target.value)}
          />
          <SelectField
            className={`custom-select ${classes.filed}`}
            value={role}
            onChange={(e) => formik.setFieldValue('role', e.target.value)}
            label="Role"
          >
            <option value={UserRoles.Admin} label={'Admin'} />
            <option value={UserRoles.Attendant} label={'Attendant'} />
          </SelectField>
          <div className={classes.createAttendantFooter}>
            <Button
              type={'submit'}
              text={'Save Attendant'}
              onClick={() => formik.handleSubmit()}
              className={classes.saveButton}
              buttonType={'secondary'}
            />
          </div>
        </div>
      </Formik>
    </ContentBlock>
  );
};
