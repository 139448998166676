import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateAttendant } from '@/api';
import { Attendant } from '@/pages';
import { AlertContext, AppContext } from '@/GlobalProvider/GlobalProvider';
import { useStyles } from './styles';
import { Button, ContentBlock, Header, Menu, TextInput } from '@/components';
import { SelectField } from '@aws-amplify/ui-react';
import { Formik, useFormik } from 'formik';
import { object as yup, string } from 'yup';
import { Switch } from '@/components/Switch';
import { UserRoles } from '@/constants';

export const EditAttendant: React.FC = ({}) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = React.useContext(AppContext);
  const { setAlert } = React.useContext(AlertContext);
  const { attendant } = location.state as { attendant: Attendant };

  const handleSave = async () => {
    const updatedAttendant = {
      ...attendant,
      enabled: isEnabled,
      name: name,
      email: email,
      role: role
    };

    try {
      const attendantResponse = await updateAttendant(
        id,
        updatedAttendant.employeeId,
        updatedAttendant
      );

      if (attendantResponse.employeeId == attendant.employeeId) {
        setAlert({
          variation: 'success',
          text: React.createElement(
            'span',
            null,
            'Attendant updated successfully'
          )
        });
        navigate(`/attendantManagement`, {
          state: { attendant: attendantResponse }
        });
      } else {
        setAlert({
          variation: 'error',
          text: React.createElement(
            'span',
            null,
            'Unable to update attendant: Please try again'
          )
        });
        navigate(`/attendantManagement`, { state: { attendant } });
      }
    } catch (error) {
      setAlert({
        variation: 'error',
        text: React.createElement(
          'span',
          null,
          `Unable to update attendant: ${error.message}`
        )
      });
      navigate(`/attendantManagement`, { state: { attendant } });
    }
  };

  const formik = useFormik({
    initialValues: {
      name: attendant.name,
      isEnabled: attendant.enabled,
      email: attendant.email,
      role: attendant.role
    },
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: () => handleSave(),
    validationSchema: yup({
      name: string()
        .trim()
        .matches(/^\S+ \S+.*$/, 'Enter both first and last name')
        .required('Enter attendant name'),
      email: string()
        .trim()
        .required('Email address is required')
        .email('Wrong email format')
    })
  });

  const { name, isEnabled, email, role } = formik.values || {};

  return (
    <ContentBlock
      hasBackButton
      backHandler={() => navigate(`/attendantManagement`)}
    >
      <div className={classes.editAttendantTitle}>Edit Attendant</div>
      <Formik onSubmit={handleSave} {...formik}>
        <div className={classes.editAttendantBody}>
          <div className={classes.attendantId}>
            <span>Attendant ID: {attendant.employeeId}</span>
          </div>
          <Switch
            title={'Status'}
            checked={isEnabled}
            activeText={'Active'}
            inactiveText={'Inactive'}
            onChange={(value) => formik.setFieldValue('isEnabled', value)}
          />
          <TextInput
            className={classes.filed}
            label="Attendant Name"
            type="text"
            name="name"
            placeholder="Enter attendant name"
            value={name}
            hasError={!!formik.errors.name}
            errorMessage={formik.errors.name}
            onChange={(e) => formik.setFieldValue('name', e.target.value)}
          />
          <TextInput
            className={classes.filed}
            label="Attendant Email"
            type="email"
            name="email"
            placeholder="Enter attendant email"
            value={email}
            hasError={!!formik.errors.email}
            errorMessage={formik.errors.email}
            onChange={(e) => formik.setFieldValue('email', e.target.value)}
          />
          <SelectField
            className={`custom-select ${classes.filed}`}
            value={role}
            onChange={(e) => formik.setFieldValue('role', e.target.value)}
            label="Role"
          >
            <option value={UserRoles.Admin} label={'Admin'} />
            <option value={UserRoles.Attendant} label={'Attendant'} />
          </SelectField>
          <div className={classes.editAttendantFooter}>
            <Button
              text={'Save Attendant'}
              onClick={() => formik.handleSubmit()}
              buttonType={'secondary'}
              className={classes.saveButton}
            />
          </div>
        </div>
      </Formik>
    </ContentBlock>
  );
};
