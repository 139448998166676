import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  header: {
    textAlign: 'center',
    marginBottom: 40
  },
  title: {
    marginBottom: 15
  }
});
