import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(({ colors }) => ({
  wrapper: {
    borderRadius: 30,
    padding: '13px 8px',
    display: 'flex',
    width: 264,
    flexDirection: 'column',
    gap: 10.5,
    margin: '0 auto 40px',
    background: 'rgba(56, 56, 56, 0.5)',
    backdropFilter: 'blur(50px)'
  },
  newAttendant: {
    position: 'absolute',
    bottom: 40,
    right: 40,
    borderRadius: 35,
    background: 'rgba(56, 56, 56, 0.5)',
    backdropFilter: 'blur(50px)',
    padding: '8px 8px 8px 30px',
    display: 'flex',
    gap: 16,
    alignItems: 'center'
  },
  topBlock: {
    display: 'flex',
    gap: 21,
    justifyContent: 'center'
  },
  name: {
    fontWeight: 600,
    color: colors.white,
    textAlign: 'center'
  },
  email: {
    color: colors.black,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    margin: 0
  },
  addButton: {
    width: 77,
    height: 54,
    border: '2px solid white',
    borderRadius: 40,
    backgroundColor: 'transparent',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  plusIcon: {
    width: '35px',
    height: '35px',
    fill: colors.white
  },
  editButton: {
    width: 54,
    height: 44,
    border: '1px solid black',
    borderRadius: 40,
    backgroundColor: 'transparent',
    cursor: 'pointer'
  },
  title: {
    textAlign: 'center',
    color: 'rgba(255,255,255, 0.6)',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    margin: 0,
    opacity: 0.5
  },
  switchButton: {
    borderRadius: 40,
    border: '2px solid white',
    background: 'transparent',
    color: colors.white,
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '20px',
    padding: 12,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  switchIcon: {
    width: '25px',
    height: '25px',
    marginLeft: 10
  }
}));
