import * as React from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import {
  Flex,
  Loader as AWSLoader,
  SelectField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  View
} from '@aws-amplify/ui-react';
import { useStyles } from './styles';
import { useParams } from 'react-router-dom';
import { getAppMeasurements, getScanImage } from '@/api';
import {
  formatDateWithTime,
  getFilteredScans,
  getScanMeasurements
} from '@/utils';
import { ScanStatus } from '@/constants';
import { AppContext, AssetsContext } from '@/GlobalProvider/GlobalProvider';
import { Typography } from '@/components';
import { Tabs } from '@/components/Tabs';

const getScanStatus = (status: string) => {
  switch (status) {
    case ScanStatus.Complete:
      return 'Complete';
    case ScanStatus.Failed:
      return 'Failed';
    case ScanStatus.WaitingForData:
      return 'Waiting for Data';
    default:
      return 'Processing';
  }
};

enum TabLabels {
  Measurements = 'Measurements',
  ScanPreview = 'Scan Preview'
}

export const ScanMeasurements = () => {
  const styles = useStyles();
  const params = useParams();
  const { id } = useContext(AppContext);

  const {
    behavior_list: { scan_review_option_panel }
  } = useContext(AssetsContext);

  const show_avatar = !!scan_review_option_panel;

  const [scans, setScans] = useState([]);
  const [currentScanId, setCurrentScanId] = useState(null);
  const [measurements, setMeasurements] = useState([]);
  const [scanImage, setScanImage] = useState(null);
  const [measurementsLoading, setMeasurementsLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [scansLoading, setScansLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<string>(TabLabels.Measurements);

  const getScans = useCallback(async () => {
    setScansLoading(true);
    try {
      const filteredScansData = await getFilteredScans(params.customerId);
      setScans(filteredScansData);
      if (filteredScansData?.length > 0) {
        setCurrentScanId(filteredScansData[0].id);
      }
    } catch (e) {
      setScans(null);
    } finally {
      setScansLoading(false);
    }
  }, []);

  const getMeasurements = useCallback(async () => {
    if (!currentScanId) return;
    setMeasurementsLoading(true);
    try {
      const scanMeasurements = await getAppMeasurements(currentScanId);
      setMeasurements(getScanMeasurements(id, scanMeasurements.output));
    } catch (e) {
      setMeasurements(null);
    } finally {
      setMeasurementsLoading(false);
    }
  }, [currentScanId]);

  const getMeasurementsPreview = useCallback(async () => {
    if (!currentScanId) return;
    setPreviewLoading(true);
    try {
      const image = await getScanImage(currentScanId);
      setScanImage(image?.output?.url);
    } catch (e) {
      setScanImage(null);
    } finally {
      setPreviewLoading(false);
    }
  }, [currentScanId]);

  useEffect(() => {
    (async () => {
      await getScans();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await getMeasurements();
      await getMeasurementsPreview();
    })();
  }, [currentScanId]);

  return (
    <View className={styles.wrapper}>
      {scansLoading ? (
        <Flex justifyContent={'center'} alignItems={'center'}>
          <AWSLoader size="large" />
        </Flex>
      ) : scans.length > 0 ? (
        <>
          <SelectField
            className={'custom-select'}
            value={currentScanId}
            onChange={(e) => setCurrentScanId(e.target.value)}
            label="Select Scan"
          >
            {scans?.map((scan) => (
              <option
                disabled={scan.status !== ScanStatus.Complete}
                key={scan.id}
                value={scan.id}
                label={`${formatDateWithTime(scan.date)} - ${getScanStatus(
                  scan.status
                )}`}
              />
            ))}
          </SelectField>

          {scanImage && (
            <Tabs
              labels={
                show_avatar
                  ? [TabLabels.Measurements, TabLabels.ScanPreview]
                  : [TabLabels.Measurements]
              }
              value={activeTab}
              onChange={setActiveTab}
            />
          )}

          {activeTab === TabLabels.Measurements ? (
            <View
              className={`${styles.tableWrapper} ${
                scanImage && styles.tableWithPreview
              }`}
            >
              {measurementsLoading && (
                <Flex
                  className={styles.loader}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <AWSLoader size="large" />
                </Flex>
              )}
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell as="th">Name</TableCell>
                    <TableCell as="th">Scan Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {measurements &&
                    measurements.map(([key, value]: any) => (
                      <TableRow key={key}>
                        <TableCell>{key}</TableCell>
                        <TableCell>{value}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </View>
          ) : (
            <>
              {show_avatar && scanImage && (
                <div className={styles.image}>
                  {previewLoading && (
                    <Flex
                      className={styles.loader}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      <AWSLoader size="large" />
                    </Flex>
                  )}
                  <img src={scanImage} />
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {/*TODO change message if need*/}
          <Typography component={'p'}>You have no scans yet</Typography>
        </>
      )}
    </View>
  );
};
