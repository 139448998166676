import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  radioButtons: {
    paddingTop: 33
  },
  heightSmallInput: {
    width: 70,
    '@media (max-width: 991px)': {
      width: 50
    },
    '@media (max-width: 576px)': {
      width: 60
    }
  },
  heightSmallInputWithoutLabel: {
    paddingTop: 24
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    '@media (max-width: 576px)': {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  buttonContainerPadding: {
    paddingTop: 15
  },
  backButton: {
    width: 200,
    '@media (max-width: 576px)': {
      marginBottom: 20
    }
  },
  termsAndConditions: {
    paddingTop: 20
  },
  submitButton: {
    width: '200px !important'
  },
  ageInput: {
    width: 155,
    '@media (max-width: 576px)': {
      width: '100%'
    }
  },
  input: {
    width: 130,
    '@media (max-width: 991px)': {
      width: 90
    },
    '@media (max-width: 576px)': {
      marginRight: 0
    }
  },
  subLabel: {
    marginLeft: '-12px',
    marginTop: '12px',
    fontSize: '14px'
  }
});
