import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  createAttendantTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center',
    paddingBottom: '15px' // Space between text and underline
  },
  attendantId: {
    fontSize: '16px',
    fontWeight: 'normal',
    alignItems: 'center'
  },
  createAttendantBody: {
    display: 'flex',
    padding: '10px',
    flexDirection: 'column',
    alignItems: 'center'
  },
  createAttendantFooter: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px'
  },
  attendantsButton: {
    backgroundColor: 'black',
    color: '#fff',
    textAlign: 'center',
    padding: '8px, 24px, 8px, 24pxx',
    border: 'none',
    borderRadius: '20px',
    cursor: 'pointer',
    display: 'inline-block',
    justifyContent: 'center',
    marginBottom: '15px',
    width: '45%',
    gap: '10px',
    height: '36px',
    marginRight: '15px'
  },
  saveButton: {
    maxWidth: '230px'
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px 0'
  },
  statusIndicator: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    backgroundColor: 'green',
    marginRight: '5px'
  },
  statusLabel: {
    marginRight: '10px', // Space between the label and the slider
    fontSize: '16px'
  },
  statusText: {
    fontSize: '16px',
    marginLeft: '10px'
  },
  switch: {
    position: 'relative',
    display: 'inline-block',
    width: '60px',
    height: '34px'
  },
  slider: {
    position: 'absolute',
    cursor: 'pointer',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: '#2bc42b', // Default background color for the slider
    transition: '.4s',
    borderRadius: '34px'
  },
  sliderHandle: {
    position: 'absolute',
    height: '26px',
    width: '26px',
    left: '4px', // Default position for "disabled"
    bottom: '4px',
    backgroundColor: 'white',
    borderRadius: '50%',
    transition: '.4s'
  },
  filed: {
    width: '320px'
  }
});
