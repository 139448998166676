import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles((theme) => ({
  leftBlock: {
    padding: '0 45px',
    height: '90%',
    '@media (max-width: 991px)': {
      padding: 0
    }
  },
  qrCodeBlock: {
    padding: 20,
    borderRadius: 20,
    backgroundColor: theme.colors.white,
    '& canvas': {
      width: '100%!important',
      height: 'auto!important'
    },
    '@media (max-width: 991px)': {
      maxWidth: '180px',
      maxHeight: '180px'
    },
    '@media (max-width: 576px)': {
      maxWidth: '100%',
      maxHeight: '100%',
      margin: '40px 0'
    }
  },
  alertMessage: {
    padding: '40px 15px',
    textAlign: 'center',
    fontSize: '25px',
    '@media (max-width: 768px)': {
      fontSize: '20px',
      padding: '20px 15px'
    }
  },
  alertMessageLink: {
    color: 'white'
  }
}));
