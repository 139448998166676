import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles((theme) => ({
  menu: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 300,
    zIndex: 20,
    '& ul': {
      listStyle: 'none',
      padding: 30
    }
  },
  openMenu: {
    '@media (max-width: 576px)': {
      width: 'calc(100vw - 40px)',
      height: 'calc(100vh - 40px)',
      background: theme.colors.white,
      margin: 20,
      borderRadius: 15,
      '& ul': {
        listStyle: 'none',
        padding: 30
      },
      '& button': {
        background: theme.colors.grey2,
        boxShadow: '0.85px 0.53px 4px 0 #909090'
      }
    }
  },
  burger: {
    margin: '25px auto 0 30px',
    width: 40,
    height: 40,
    cursor: 'pointer',
    position: 'relative',
    '& span, &:before, &:after': {
      display: 'inline-block',
      position: 'absolute',
      width: '100%',
      height: 4,
      background: theme.colors.white
    },
    '& span': {
      top: 18,
      visibility: 'visible',
      opacity: 1
    },
    '&:before': {
      content: '""',
      top: 2,
      transform: 'rotate(0deg)'
    },
    '&:after': {
      content: '""',
      top: 34,
      transform: 'rotate(0deg)'
    }
  },
  openBurger: {
    '@media (max-width: 576px)': {
      transform: 'scale(0.5)',
      margin: '15px 15px 0 auto'
    },
    '& span': {
      opacity: 0,
      visibility: 'hidden'
    },
    '&:before': {
      content: '""',
      top: 18,
      transform: 'rotate(45deg)',
      '@media (max-width: 576px)': {
        background: theme.colors.black
      }
    },
    '&:after': {
      content: '""',
      top: 18,
      transform: 'rotate(-45deg)',
      '@media (max-width: 576px)': {
        background: theme.colors.black
      }
    }
  },
  button: {
    marginBottom: 20
  }
}));
