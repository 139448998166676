import { ContentBlock, Typography } from '@/components';
import * as React from 'react';
import { useContext, useState } from 'react';
import { useStyles } from './styles';
import { SearchForm, SearchCustomerData } from './SearchForm';
import { useNavigate } from 'react-router-dom';
import { getCustomerProvision } from '@/api';
import {
  AlertContext,
  AppContext,
  AssetsContext
} from '@/GlobalProvider/GlobalProvider';
import { useIframeMessageHandler } from '@/utils';
import { SearchByIdForm } from './SearchByIdForm';
import { CustomerAccounts } from '@/constants';

const getTitle = (customer_account_handle: string) => {
  switch (customer_account_handle) {
    case CustomerAccounts.EmailOnly:
      return 'Search by email address';
    case CustomerAccounts.PhoneOnly:
      return 'Search by phone number';
    case CustomerAccounts.ThirdPartyAccountId:
      return 'Search by Subject ID';
    default:
      return 'Search by phone number or email address';
  }
};

export const SearchCustomer = () => {
  const styles = useStyles();
  const { id } = useContext(AppContext);
  const { setAlert } = useContext(AlertContext);
  const {
    behavior_list: { customer_account_handle }
  } = useContext(AssetsContext);
  const navigate = useNavigate();
  const [isAPICallInProgress, setIsAPICallInProgress] = useState(false);

  useIframeMessageHandler();

  const searchHandler = async ({ email, phone }: SearchCustomerData) => {
    setIsAPICallInProgress(true);
    try {
      if (email) {
        const customer = await getCustomerProvision(id, email.toLowerCase());
        if (customer.id) {
          navigate(`/customers/${customer.id}`);
          return;
        }
      }

      if (phone) {
        const customer = await getCustomerProvision(id, phone);
        if (customer.id) {
          navigate(`/customers/${customer.id}`);
          return;
        }
      }

      setAlert({
        text: 'User with this data was not found!',
        variation: 'error'
      });
    } catch (e) {
      const error = JSON.stringify(e.message);

      if (error === '"Network Error"') {
        setAlert({
          text: 'Check your internet and try again.',
          variation: 'error'
        });
      } else {
        setAlert({
          text: 'Something went wrong',
          variation: 'error'
        });
      }
    } finally {
      setIsAPICallInProgress(false);
    }
  };

  const searchByIdHandler = async (accountId: string) => {
    setIsAPICallInProgress(true);
    try {
      const customer = await getCustomerProvision(id, accountId);
      if (customer.id) {
        navigate(`/customers/${customer.id}/${encodeURI(accountId)}`);
        return;
      }

      setAlert({
        text: 'User with this data was not found!',
        variation: 'error'
      });
    } catch (e) {
      const error = JSON.stringify(e.message);

      if (error === '"Network Error"') {
        setAlert({
          text: 'Check your internet and try again.',
          variation: 'error'
        });
      } else {
        setAlert({
          text: 'Something went wrong',
          variation: 'error'
        });
      }
    } finally {
      setIsAPICallInProgress(false);
    }
  };

  return (
    <ContentBlock hasBackButton>
      <div className={styles.header}>
        <Typography className={styles.title} component="h2">
          {customer_account_handle === CustomerAccounts.ThirdPartyAccountId
            ? 'Subject Search'
            : 'Customer Search'}
        </Typography>
        {/*TODO remove if no need, in a new design it`s missing*/}
        {/*<Typography component="h6">*/}
        {/*  {getTitle(customer_account_handle)}*/}
        {/*</Typography>*/}
      </div>
      {customer_account_handle === CustomerAccounts.ThirdPartyAccountId ? (
        <SearchByIdForm
          onSubmit={searchByIdHandler}
          isLoading={isAPICallInProgress}
        />
      ) : (
        <SearchForm onSubmit={searchHandler} isLoading={isAPICallInProgress} />
      )}
    </ContentBlock>
  );
};
