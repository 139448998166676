import * as React from 'react';
import { CSSProperties, FC } from 'react';
import { useStyles } from './styles';
import { Flex } from '@aws-amplify/ui-react';
import { BsQrCodeScan } from 'react-icons/bs';
import { AdditionalInfoStates } from '@/pages/Customer/Customer';

interface QRCodeButtonProps {
  onClick: (v: AdditionalInfoStates | null) => void;
  open?: boolean;
  style?: CSSProperties;
}

export const QRCodeButton: FC<QRCodeButtonProps> = ({
  onClick,
  open,
  style
}) => {
  const styles = useStyles();

  const clickHandler = () => {
    if (open) {
      onClick(null);
    } else {
      onClick(AdditionalInfoStates.QRCode);
    }
  };

  return (
    <Flex
      onClick={clickHandler}
      style={style}
      alignItems={'center'}
      justifyContent={'center'}
      className={styles.qrCodeButtonWrapper}
    >
      <BsQrCodeScan />
      {open ? <span>Hide QR</span> : <span>Show QR</span>}
    </Flex>
  );
};
