import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  radioGroupField: {
    width: '100%',
    '& .amplify-label': {
      color: '#ffffff'
    },
    '& .amplify-radiogroup': {
      width: '100%'
    },
    '@media (min-width: 767px)': {
      minWidth: '270px'
    },
    '@media (min-width: 991px)': {
      minWidth: '370px'
    }
  },
  radioGroupWrapper: {
    width: '100%',
    borderRadius: '50px',
    border: '1px solid rgba(255, 255, 255, 0.20)',
    background: 'rgba(56, 56, 56, 0.50)',
    overflow: 'hidden',
    gap: 'initial',
    padding: '1px',
    '@media (max-width: 991px)': {
      minWidth: '180px'
    }
  },
  radioButton: {
    flex: 1,
    textAlign: 'center',
    padding: '4px 10px',
    borderRadius: '44px',
    backgroundColor: 'transparent',
    color: '#ffffff',
    cursor: 'pointer',
    transition: 'background-color 0.5s, color 0.5s',
    justifyContent: 'center',
    '& span': {
      color: 'rgba(255, 255, 255, 0.80)'
    },
    '& span.amplify-radio__button': {
      display: 'none'
    },
    '&.checked': {
      backgroundColor: '#4287E1',
      '& span': {
        color: '#000'
      }
    }
  }
});
