import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  wrapper: ({ backgroundUrl }: { backgroundUrl: string }) => ({
    height: '100vh',
    width: '100vw',
    ...(backgroundUrl
      ? { backgroundImage: `url(${backgroundUrl})` }
      : { backgroundColor: '#6f6f6f' }),
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    '@media (max-width: 991px)': {
      height: 'auto',
      minHeight: '100vh'
    }
  }),
  bottomPanel: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    backgroundColor: '#393939',
    width: '100%',
    padding: '10px 25px'
  },
  icon: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  connectionData: {
    position: 'absolute',
    background: 'white',
    bottom: 40,
    width: 300,
    height: 300
  }
});
