import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  tabTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    margin: '0 20px',
    textAlign: 'center'
  },
  tabContent: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 0'
  },
  listSection: {
    width: '48%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  listTitle: {
    fontSize: '18px',
    fontWeight: '600', // Slightly bolder than before
    marginBottom: '20px'
  },
  person: {
    display: 'flex',
    justifyContent: 'center', // Center the text in the circles
    alignItems: 'center',
    marginBottom: '10px',
    backgroundColor: '#e9ecef',
    borderRadius: '50%', // Full circle
    padding: '15px', // Adjust padding to create a larger circle
    width: '60px', // Fixed width for circle
    height: '60px', // Fixed height for circle
    boxSizing: 'border-box'
  },
  avatar: {
    display: 'none'
  },
  name: {
    fontSize: '16px',
    fontWeight: 'bold', // Names are bold
    textAlign: 'center'
  },
  addNewButton: {
    backgroundColor: '#dee2e6', // Lighter gray background
    color: '#495057',
    fontSize: '30px'
  },

  // Add responsive styles as needed
  '@media (max-width: 768px)': {
    associatesContainer: {
      width: '100%' // Adjust width to fill the screen}
    }
  },
  personCircle: {
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto 10px',
    fontSize: '24px',
    fontWeight: 'bold',
    position: 'relative'
  },
  innerCircle: {
    width: '82px', // Slightly smaller to create a concentric effect
    height: '80px',
    borderRadius: '50%',
    backgroundColor: 'transparent', // Ensure solid white background
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '32px',
    fontWeight: 'normal',
    color: '#fff',
    border: '2px solid rgba(255, 255, 255, 0.20)', // Ensure the border is also solid
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)', // Center the circle
    zIndex: 2, // Ensure it's above any pseudo-element,
    cursor: 'pointer',
    '&:hover': {
      background:
        'radial-gradient(circle at center, #b3b3c0 0%, #9191a0 50%, #707070 100%)' // Slightly lighter gradient on hover
    }
  },
  personName: {
    textAlign: 'center',
    fontWeight: 'normal',
    fontSize: '14px'
  },
  circleContainer: {
    minWidth: 500,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)', // 3 items per row
    gap: '20px',
    alignItems: 'start', // Align items to the start of the cell
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 200px)', // Adjust the height as needed
    padding: '20px',
    '@media (max-width: 991px)': {
      minWidth: 480
    },
    '@media (max-width: 576px)': {
      minWidth: '100%',
      padding: '20px 0',
      gap: '30px',
      overflowX: 'hidden'
    }
  },
  circleItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center the items horizontally
    justifyContent: 'center' // Center the items vertically
  },
  circle: {
    width: '80px', // Bigger circle size
    height: '80px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px', // Consistent margin for all circles
    fontSize: '48px', // Bigger text for initials
    fontWeight: 'bold',
    border: '4px solid rgba(0, 0, 0, 0.1)', // Semi-transparent border
    backgroundColor: 'transparent',
    color: '#000', // Adjust as needed
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)' // Slight hover effect for interactive elements
    }
  },
  newAttendantButton: {
    width: '80px', // Bigger circle size
    height: '80px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto 10px', // Center the circle and add margin below
    fontSize: '36px', // Bigger text for initials
    fontWeight: 'normal',
    background: 'rgba(255, 255, 255, 0.20)', // Light gray background
    //edge color also light gray
    borderColor: '#dfdfdf',

    color: '#000', // Adjust as needed
    position: 'relative',
    cursor: 'pointer', // To indicate the button is clickable
    '&:hover': {
      background:
        'radial-gradient(circle at center, #b3b3c0 0%, #9191a0 50%, #707070 100%)' // Slightly lighter gradient on hover
    }
  },
  plusIcon: {
    width: '35px',
    height: '35px',
    color: '#fff'
  },
  addButtonLabel: {
    marginTop: '5px', // Add space between the button and the label
    textAlign: 'center',
    fontWeight: 'normal',
    fontSize: '14px'
  },
  circleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }
});
