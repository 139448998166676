export enum ScanStatus {
  Canceled = 'canceled',
  Complete = 'complete',
  Failed = 'failed',
  WaitingForData = 'waiting for data'
}
export enum CustomerAccounts {
  EmailOnly = 'email_only',
  PhoneOnly = 'phone_only',
  ThirdPartyAccountId = 'third_party_account_id',
  EmailRequiredPhoneOptional = 'email_required_phone_optional',
  EmailAndPhone = 'email_and_phone',
  EmailOrPhone = 'email_or_phone'
}
export enum GenderSelectionFlags {
  AssumedMale = 'assumed_male',
  AssumedFemale = 'assumed_female'
}
export enum Genders {
  Female = 'female',
  Male = 'male'
}
export enum UserRoles {
  Admin = 'admin',
  Attendant = 'attendant'
}
export enum HeightWeightUnits {
  KgCm = 'Kg&Cm',
  LbsFtInches = 'Lb&Ft/Inch'
}
