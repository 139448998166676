import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  wrapper: {
    marginTop: 32
  },
  tableWrapper: {
    overflowY: 'auto',
    overflowX: 'visible',
    maxWidth: 700,
    maxHeight: 420,
    marginTop: 35,
    '@media (max-width: 991px)': {
      width: '100%'
    }
  },
  tableCell: {
    maxWidth: 190
  }
});
