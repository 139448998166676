import * as React from 'react';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import {
  Flex,
  Loader as AWSLoader,
  SelectField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  View
} from '@aws-amplify/ui-react';
import { useStyles } from './styles';
import { getDXLRTWMeasurements } from '@/api';
import { Typography } from '@/components';
import { AlertContext, AssetsContext } from '@/GlobalProvider/GlobalProvider';
import Select from 'rc-select';
import { IoIosArrowDown } from 'react-icons/io';
import { MdDone } from 'react-icons/md';

interface SizeRecommendationsProps {
  scanId: string;
  setFullScreen?: (v: boolean) => void;
}

export const SizeRecommendations: FC<SizeRecommendationsProps> = ({
  scanId,
  setFullScreen
}) => {
  const styles = useStyles();
  const selectRef = useRef(null);
  const { behavior_list } = useContext(AssetsContext) || {};
  const { setAlert } = React.useContext(AlertContext);

  const [recommendations, setRecommendations] = useState(null);
  const [loading, setLoading] = useState(null);
  const [brands, setBrands] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState({});
  const [brandValue, setBrandValue] = useState([]);

  const getMeasurements = async () => {
    setLoading(true);
    try {
      const [DXLRTWMeasurements] = await Promise.all([
        getDXLRTWMeasurements(scanId)
      ]);

      const fieldsToKeep = ['Jacket', 'DressShirt', 'SportShirt', 'DressPants'];
      const result = fieldsToKeep.reduce((acc, key) => {
        acc[key] = DXLRTWMeasurements.output[key];
        return acc;
      }, {});
      setBrands(DXLRTWMeasurements.output.Brands);
      setRecommendations(result);
    } catch (e) {
      setRecommendations(null);
      setAlert({
        variation: 'error',
        text: React.createElement(
          'span',
          null,
          'Unable to load size recommendations for this brand'
        )
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSelectChange = (value) => {
    if (selectRef.current) {
      selectRef.current.blur();
    }

    setBrandValue(value);
    const selected = value.reduce((result, brand) => {
      if (brands[brand]) {
        result[brand] = brands[brand];
      }
      return result;
    }, {});

    setSelectedBrands(selected);
    if (Object.keys(value)?.length > 2) {
      setFullScreen(true);
    } else {
      setFullScreen(false);
    }
  };

  const getUniqueKeys = (brands) => {
    const keysSet = new Set();

    Object.values(brands).forEach((brand) => {
      Object.keys(brand).forEach((key) => {
        keysSet.add(key);
      });
    });

    return Array.from(keysSet);
  };

  const prepareTableData = (selectedBrands) => {
    const uniqueKeys = getUniqueKeys(selectedBrands);
    return uniqueKeys.map((item: any) => {
      const row = { name: item };
      Object.keys(selectedBrands).forEach((brand) => {
        row[brand] = selectedBrands[brand][item] || '-';
      });
      return row;
    });
  };

  useEffect(() => {
    getMeasurements();
  }, [scanId]);

  return (
    <View className={styles.wrapper}>
      <label style={{ display: 'block' }}>Select Brand & Garment</label>
      <Select
        ref={selectRef}
        showAction={['focus']}
        placeholder={'Select Brand & Garment'}
        autoFocus
        value={brandValue}
        choiceTransitionName="rc-select-selection__choice-zoom"
        mode="multiple"
        suffixIcon={<IoIosArrowDown />}
        menuItemSelectedIcon={<MdDone />}
        dropdownMatchSelectWidth
        loading={loading}
        onChange={handleSelectChange}
        options={Object.keys(brands).map((key) => ({ label: key, value: key }))}
      />
      {/*<SelectField*/}
      {/*  className={'custom-select'}*/}
      {/*  value={brand}*/}
      {/*  placeholder=""*/}
      {/*  label="Select Brand"*/}
      {/*  onChange={(v) => setBrand(v.target.value)}*/}
      {/*>*/}
      {/*  {Object.entries(behavior_list?.rtw_measures).map(*/}
      {/*    ([key, value]: any) => (*/}
      {/*      <option key={key} value={value} label={key} />*/}
      {/*    )*/}
      {/*  )}*/}
      {/*</SelectField>*/}
      <View className={styles.tableWrapper}>
        {loading ? (
          <Flex justifyContent={'center'} alignItems={'center'}>
            <AWSLoader size="large" />
          </Flex>
        ) : Object.keys(selectedBrands).length > 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell as="th">Name</TableCell>
                {Object.keys(selectedBrands).map((b) => (
                  <TableCell key={b} as="th">
                    {b}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {/*<TableBody>*/}
            {/*  {Object.entries(selectedBrands).map(([key, value]: any) => (*/}
            {/*    <TableRow key={key}>*/}
            {/*      <TableCell>*/}
            {/*        {key.replace(/([a-z])([A-Z])/g, '$1 $2')}*/}
            {/*      </TableCell>*/}
            {/*      <TableCell>{value}</TableCell>*/}
            {/*    </TableRow>*/}
            {/*  ))}*/}
            {/*</TableBody>*/}
            <TableBody>
              {prepareTableData(selectedBrands).map((row) => (
                <TableRow key={row.name}>
                  <TableCell className={styles.tableCell}>{row.name}</TableCell>
                  {Object.keys(selectedBrands).map((brand) => (
                    <TableCell className={styles.tableCell} key={brand}>
                      {row[brand]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <>
            {/*TODO change message if need*/}
            <Typography component={'p'}>
              Please select brand to see recommendations
            </Typography>
          </>
        )}
      </View>
    </View>
  );
};
