import * as React from 'react';
import { Auth, I18n } from 'aws-amplify';
import { withAuthenticator, translations } from '@aws-amplify/ui-react';
import { Router } from '@/router';
import { GlobalProvider } from '@/GlobalProvider';

I18n.putVocabularies(translations);

const originalMessage =
  "1 validation error detected: Value at 'username' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+";
const translatedMessage = 'Username cannot have whitespace.';

I18n.putVocabularies({
  en: {
    [originalMessage]: [translatedMessage],
    Password: 'Passphrase',
    ['Reset your password']: 'Reset your passphrase',
    ['New Password']: 'New Passphrase',
    ['Confirm Password']: 'Confirm Passphrase',
    ['Forgot your password?']: 'Forgot your passphrase?',
    ['Password does not conform to policy: Password not long enough']:
      'Passphrase does not conform to policy: Passphrase must be a minimum of 16 characters',
    'Incorrect username or password.': 'Incorrect username or passphrase.'
  }
});

const AuthenticatorComponent = (props) => {
  if (props.user) {
    return (
      <GlobalProvider>
        <Router />
      </GlobalProvider>
    );
  }
  return null;
};

const services = {
  async handleSignIn(formData) {
    window.localStorage.removeItem('attendantToken');
    return Auth.signIn({
      username: formData.username.replace(/ /g, ''),
      password: formData.password
    });
  }
};

export const Authenticator = withAuthenticator(AuthenticatorComponent, {
  hideSignUp: true,
  services: services
});
