import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  profileTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center',
    paddingBottom: '3px',
    display: 'inline-block',
    marginBottom: '35px',
    '@media (max-width: 576px)': {
      marginBottom: '10px'
    }
  },
  input: {
    maxWidth: 400,
    border: 'none',
    outline: 'none',
    fontSize: '32px',
    lineHeight: '28px',
    textAlign: 'center',
    margin: '0 0 20px',
    backgroundColor: 'transparent',
    color: '#fff',
    '@media (max-width: 576px)': {
      width: '100%',
      margin: '0 0 5px'
    }
  },
  buttonWrapper: {
    maxWidth: '252px',
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    margin: '0 0 30px'
  },
  button: {
    width: '60px',
    height: '60px',
    margin: '12px',
    backgroundColor: 'transparent',
    borderRadius: '50%',
    border: 'none',
    fontSize: '20px',
    fontWeight: '600',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    '&:focus': {
      background: 'rgba(255, 255, 255, 0.10)'
    },
    '@media (max-width: 576px)': {
      width: '40px',
      height: '40px',
      margin: '12px 22px'
    }
  },
  backspaceIcon: {
    width: 35,
    height: 25
  },
  signButton: {
    maxWidth: '194px'
  }
});
