import * as React from 'react';
import { useStyles } from './styles';
import { FiRefreshCw } from 'react-icons/fi';
import { Flex, Loader } from '@aws-amplify/ui-react';
import { FC } from 'react';

interface RefreshButtonProps {
  onClick: () => void;
  loading?: boolean;
}

export const RefreshButton: FC<RefreshButtonProps> = ({ onClick, loading }) => {
  const styles = useStyles();

  return (
    <Flex
      onClick={onClick}
      alignItems={'center'}
      justifyContent={'center'}
      className={`${styles.refreshButtonWrapper} ${loading && styles.loading}`}
    >
      {loading ? <Loader /> : <FiRefreshCw />}
    </Flex>
  );
};
