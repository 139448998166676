import { ElementType } from 'react';
import { createUseStyles } from 'react-jss';

interface TypographyStylesProps {
  component: ElementType;
}

export const useStyles = createUseStyles((theme) => {
  const { colors } = theme;
  return {
    typography: ({ component }: TypographyStylesProps) => {
      switch (component) {
        case 'h1':
          return {
            color: colors.white,
            margin: 0,
            fontSize: 55,
            fontWeight: 500,
            textTransform: 'uppercase',
            textAlign: 'center'
          };
        case 'h2':
          return {
            color: colors.white,
            margin: 0,
            fontSize: 20,
            fontWeight: 400,
            letterSpacing: 1.1,
            position: 'relative',
            display: 'inline-block',
            '@media (max-width: 576px)': {
              fontSize: 16
            }
          };
        case 'h3':
          return {
            color: colors.white,
            margin: 0,
            fontSize: 18,
            fontWeight: 400
          };
        case 'h4':
          return {
            color: colors.white,
            margin: 0,
            fontSize: 25,
            fontWeight: 700,
            letterSpacing: 1.1,
            position: 'relative',
            '@media (max-width: 762px)': {
              fontSize: 20
            },
            '@media (max-width: 576px)': {
              fontSize: 16
            }
          };
        case 'h6':
          return {
            fontStyle: 'italic',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            color: 'rgba(255, 255, 255, 0.60)',
            margin: 0,
            fontSize: 16,
            fontWeight: 400
          };
        case 'p':
          return {
            color: colors.white,
            fontWeight: 300,
            fontSize: 18,
            margin: 0,
            '& span': {
              fontWeight: 500
            },
            '@media (max-width: 576px)': {
              fontSize: 16
            }
          };
      }
    }
  };
});
