import * as React from 'react';
import { useContext, useState } from 'react';
import { useStyles } from './styles';
import {
  AlertContext,
  AppContext,
  AssetsContext,
  AttendantContext
} from '@/GlobalProvider/GlobalProvider';
import { useNavigate } from 'react-router-dom';
import { getAttendant } from '@/api';
import { getAttendantUser } from '@/utils';
import { IoBackspaceOutline } from 'react-icons/io5';
import { Button, ContentBlock, Header, Loader } from '@/components';
import { Flex } from '@aws-amplify/ui-react';

const digitArray: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
const tokenRemovalTime = 3600000; //one hour

export const EnterAttendantId: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { behavior_list } = useContext(AssetsContext);
  const { id } = useContext(AppContext);
  const { setAlert } = useContext(AlertContext);
  const { setAttendantData } = React.useContext(AttendantContext);

  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);

  const isOnlyDigit = behavior_list?.attendant_key_pad === 'pinpad';

  const handleDigitClick = (digit) => {
    setInputValue((prevValue) => prevValue + digit);
  };

  const handleDelete = () => {
    setInputValue((prevValue) => prevValue.slice(0, -1));
  };

  const handleSignIn = async () => {
    if (!inputValue) return;
    setLoading(true);
    const res = await getAttendant(id, inputValue);
    setLoading(false);
    if (res.token) {
      const attendantUser = getAttendantUser(res.token);
      if (attendantUser.employee_id) {
        window.localStorage.setItem('attendantToken', res.token);
        setTimeout(
          () => window.localStorage.removeItem('attendantToken'),
          tokenRemovalTime
        );
        setAttendantData(attendantUser);
        navigate('/');
      }
    } else {
      setAlert({
        text: 'Attendant not found: Invalid or missing attendant ID',
        variation: 'error'
      });
    }
  };

  return (
    <ContentBlock hasBackButton loading={loading}>
      <Flex
        direction={'column'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <span className={classes.profileTitle}>Enter Attendant ID</span>
        <input
          className={classes.input}
          readOnly={isOnlyDigit}
          type="text"
          value={inputValue}
          onChange={(event) =>
            !isOnlyDigit && setInputValue(event.target.value)
          }
        />
        {isOnlyDigit && (
          <div className={classes.buttonWrapper}>
            {digitArray.map((digit) => (
              <button
                className={classes.button}
                key={digit}
                onClick={() => handleDigitClick(digit)}
              >
                {digit}
              </button>
            ))}
            <button className={classes.button} onClick={handleDelete}>
              <IoBackspaceOutline className={classes.backspaceIcon} />
            </button>
          </div>
        )}
        <Button
          text={'Sign In'}
          buttonType={'secondary'}
          isDisabled={!inputValue}
          className={classes.signButton}
          onClick={handleSignIn}
        />
      </Flex>
    </ContentBlock>
  );
};
